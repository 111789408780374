import { memo, type FunctionComponent } from 'react'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { GenericProblemMessage } from '../../../organisms/generic-problem-message'

export const WrongBrowserMessage: FunctionComponent = memo(function WrongBrowserMessage() {
  const navigateToLogin = useNavigateTo({ to: '/login' })

  return (
    <GenericProblemMessage
      title="Oups"
      buttonText="Se connecter avec ce navigateur"
      onButtonClick={navigateToLogin}
      principalMessage="Ce lien n'était pas destiné à ce navigateur internet"
      firstSubText="Veuillez ouvrir le lien dans votre email avec le navigateur internet avec lequel vous avez commencé la connexion."
      secondSubText="Mais vous pouvez vous connecter avec celui-ci, en cliquant ci-dessous.
      "
    />
  )
})
